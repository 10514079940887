
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PRIVACY_MODAL_COMPONENT_KEY = 'PrivacyModal'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface PrivacyModalConfig {
  defaultChecked: boolean
  hasPrimaryBtn: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PRIVACY_MODAL_COMPONENT_CONFIG_MAP: PrivacyModalConfig = {
  defaultChecked: true,
  hasPrimaryBtn: false
}
