















































import { Component, Mixins } from 'vue-property-decorator'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { IPrivacyManager, IPrivacyManagerType, PreferenceRecord } from '../../services'

import { AbstractModal } from '../AbstractModal/AbstractModal.vue'
import {
  PRIVACY_MODAL_COMPONENT_CONFIG_MAP,
  PRIVACY_MODAL_COMPONENT_KEY,
  PrivacyModalConfig
} from './PrivacyModal.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PrivacyModal>({
  name: 'PrivacyModal',
  created () {
    this.config = this.getComponentConfig(PRIVACY_MODAL_COMPONENT_KEY, { ...PRIVACY_MODAL_COMPONENT_CONFIG_MAP })
  },
  mounted () {
    this.getPrivacySite()

    this.initPreferencesModal()
  }
})
export class PrivacyModal extends Mixins(AbstractModal, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(IPrivacyManagerType)
  public readonly privacyManager!: IPrivacyManager

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  protected config!: PrivacyModalConfig

  /**
   * Model object
   */
  public model: PreferenceRecord = {}

  /**
   * Privacy Policy location
   * @see openPreferencesModal
   */
  public policyLocation: string | null = null

  /**
   * Toggle options visibility
   */
  public optionsExpanded: boolean | undefined = false

  /**
   * Track if user did expand options at least one time. This conditions is used while setting preferences
   * @see onAccept
   */
  public wereOptionsExpanded: boolean = false

  public get hasPrimaryBtn (): boolean {
    return this.getConfigProperty<boolean>('hasPrimaryBtn')
  }

  public get defaultChecked (): boolean {
    return this.getConfigProperty<boolean>('defaultChecked')
  }

  public get privacyPolicyLocation (): string {
    return this.policyLocation ?? ''
  }

  public set privacyPolicyLocation (value: string) {
    this.policyLocation = value
  }

  /**
   * Accept preferences and set them as localstorage
   */
  public onAccept (isAcceptAll: boolean): void {
    if (this.model) {
      if (isAcceptAll) {
        for (const key of Object.keys(this.model)) {
          this.model[key] = true
        }
      }
      /**
       * Set chosen preferences by clicking the 'Akceptuj wybrane' button
       */
      this.privacyManager.setPreferences(this.model)

      this.eventBus.emit('app:consents.update', this.model)
    }

    /**
     * Close options,
     * Close modal
     */
    this.optionsExpanded = false
    this.close()
  }

  /**
   * Expand / show available preferences with it's current values
   */
  public onExpand (): void {
    this.optionsExpanded = !this.optionsExpanded

    this.wereOptionsExpanded = true
  }

  /**
   * Creates inputName property in preferencesVariants object from provided 'key' property name.
   * InputName is an iterable key passed in the template while creating Check inputs
   * @see VariantEntity
   */
  public get extendedPreferences (): AnyObject[] {
    const options = []

    for (const key of this.privacyManager.options) {
      const preference = this.$t(`_.privacy.${key}`) as AnyObject
      options.push({
        description: preference.description ?? '',
        inputName: key.toLowerCase(),
        key,
        title: preference.title ?? key
      })
    }

    return options
  }

  /**
   * Compose model with all required values.
   */
  protected composeModel (preferences: PreferenceRecord): PreferenceRecord {
    for (const key of this.privacyManager.options) {
      if (
        typeof preferences[key] !== 'boolean' ||
        this.privacyManager.requiredOptions.includes(key)
      ) {
        const requiredCollection = this.privacyManager.requiredOptions
        if (requiredCollection.includes(key)) {
          preferences[key] = true
        } else {
          preferences[key] = this.defaultChecked
        }
      }
    }

    return preferences
  }

  /**
   * Get Privacy Policy URL
   */
  protected getPrivacySite (): void {
    const sites = this.siteService?.getActiveSiteUrls()
    if (sites.privacy) {
      this.policyLocation = sites.privacy
    } else {
      this.policyLocation = '/'
    }
  }

  /**
   * Check permissions and initialize data
   * @private
   */
  protected initPreferencesModal (): void {
    /**
     * Close modal instantly if user has all preferences set
     * in case when model opens not on purpose
     */
    const hasAllPermissions = this.privacyManager.hasAllPreferencesSet()

    /**
     * enableWhenAllPreferencesSet enabled modal to open even if all preferences has been set
     */
    if (!this.payload.enableWhenAllPreferencesSet) {
      if (hasAllPermissions) this.$emit('close')
    }

    /**
     * Compose model with privacy data
     */
    this.model = this.composeModel(
      this.privacyManager.getPreferences()
    )
  }

  /**
   * TODO: Invoke method in mounted() hook in App root component, listening on Storage changes
   */
  protected listenOnChanges (): void {
    window.addEventListener('storage', () => {
      //
    })
  }
}

export default PrivacyModal
